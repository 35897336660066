import React from 'react';
import { Link, useStaticQuery, graphql } from 'gatsby';
import 'normalize.css';
import 'prismjs/themes/prism.css';
import styled from 'styled-components';
import { FaTwitter } from 'react-icons/fa';
import Header from './Header';
import GlobalStyles from '../styles/GlobalStyles';
import Typography from '../styles/Typography';

const MainDivStyles = styled.div`
  margin: 0 auto;
  max-width: 960;
  padding: 0 1.0875rem 1.45rem;
`;

const FooterStyles = styled.footer`
  margin-top: 2rem;
  text-align: center;

  a {
    color: #222;
  }
`;

export default function Layout({ children }) {
  const data = useStaticQuery(graphql`
    query SiteTitleQuery {
      site {
        siteMetadata {
          title
        }
      }
    }
  `);

  const siteTitle = data.site.siteMetadata?.title || 'Title';
  return (
    <>
      <GlobalStyles />
      <Typography />
      <Header siteTitle={siteTitle} />
      <MainDivStyles>
        <main>{children}</main>
        <FooterStyles>
          <hr />
          <div>
            <a>
              <FaTwitter />
            </a>
          </div>
          <Link to="/privacy">Privacy Policy</Link>
          <p>
            © {new Date().getFullYear()}, Built with
            {` `}
            <a href="https://www.gatsbyjs.com">Gatsby</a>
          </p>
        </FooterStyles>
      </MainDivStyles>
    </>
  );
}
