import { createGlobalStyle } from 'styled-components';

const GlobalStyles = createGlobalStyle`

    body {
        padding: 100px;
    }
    
    a {
        text-decoration: none;
    }
`;

export default GlobalStyles;
