import { Link } from 'gatsby';
import React from 'react';
import styled from 'styled-components';

const HeaderStyles = styled.header`
  a {
    font-family: Futura, Trebuchet MS, Arial, sans-serif;
    color: #222222;
  }

  h1 {
    margin: 0;
  }

  .menu {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr;
    margin-top: 10px;
    margin-bottom: 60px;
  }
`;

export default function Header() {
  return (
    <HeaderStyles>
      <div>
        <h1>
          <Link to="/">Learning Objective</Link>
        </h1>
        <div className="menu">
          <div>
            <Link to="/">Home</Link>
          </div>
          <div>
            <Link to="/products">Products</Link>
          </div>
          <div>
            <Link to="/support">Support</Link>
          </div>
          <div>
            <Link to="/about">About</Link>
          </div>
        </div>
      </div>
    </HeaderStyles>
  );
}
