import { createGlobalStyle } from 'styled-components';

const Typography = createGlobalStyle`

    h1, h2, h3, h4, h5, h6 {
        font-family: Futura,Trebuchet MS,Arial,sans-serif; 
    }

    h1 {
        font-size: 28px;
        text-transform: uppercase;
        letter-spacing: 4px;
        font-weight: 600;
        font-style: normal;
        line-height: 1.2em;
    }

    p {
        font-family: Futura,Trebuchet MS,Arial,sans-serif; 
        font-size: 16px;
        /* text-transform: uppercase; */
        /* letter-spacing: 4px; */
        /* font-weight: 600; */
        font-style: normal;
        line-height: 1.5em;
    }

    /* a {
        font-family: Futura,Trebuchet MS,Arial,sans-serif; 
    } */
`;

export default Typography;
